'use es6';

import { browserSupportsCors, buildWebAnalytics, getDomain, parseJson } from './utils';
const {
  bender
} = require('legacy-hubspot-bender-context');
const {
  depVersions,
  project
} = bender;
let bundleVersion = 'unknown';
try {
  const rawVersion = depVersions[project];
  const versionNumberRegex = /static-(\d+\.\d+)/;
  bundleVersion = rawVersion === 'static' ? 'local' : versionNumberRegex.exec(rawVersion)[1] || 'unknown';
} catch (err) {
  console.error(err);
}
const ACCEPT_HEADER = 'Accept';
const API_ROOT = 'feedback/public';
const CONTENT_TYPE_HEADER = 'Content-Type';
const JSON_CONTENT_TYPE = 'application/json';

/* XHR config fetching--for modern browsers
 * XDR config fetching--for IE9
 */
const buildRequest = () => {
  // XDomainRequest is deprecated and only exists in IE8/9
  // This should be removed when IE9 support is not required
  // eslint-disable-next-line no-undef
  return browserSupportsCors ? new XMLHttpRequest() : new XDomainRequest();
};
const buildUrl = url => {
  return url.indexOf('?') > -1 ? `${url}&bundleVersion=${bundleVersion}` : `${url}?bundleVersion=${bundleVersion}`;
};
const buildGdprConsent = (gdprSettings, isConsentsToReceiveCommunications) => {
  const gdprConsentType = gdprSettings ? gdprSettings.gdprConsentType : 'NONE';
  const legitimateInterestLawfulBasis = gdprSettings && gdprConsentType === 'LEGITIMATE_INTEREST' ? gdprSettings.legitimateInterestLawfulBasis : undefined;
  return {
    gdprConsentType,
    legitimateInterestLawfulBasis,
    isConsentsToReceiveCommunications
  };
};
const associateEmailWithContact = (feedbackHubapiDomain, contactId, email, submissionId, portalId, gdprSettings, isConsentsToReceiveCommunications) => {
  const request = buildRequest();
  const domain = getDomain(feedbackHubapiDomain);
  const url = buildUrl(`https://${domain}/${API_ROOT}/v1/identity-association/contact`);
  const data = {
    contactId,
    email,
    portalId,
    submissionId,
    surveyType: 'KNOWLEDGE',
    gdprConsent: buildGdprConsent(gdprSettings, isConsentsToReceiveCommunications)
  };
  request.open('POST', url);
  request.setRequestHeader(CONTENT_TYPE_HEADER, JSON_CONTENT_TYPE);
  request.send(JSON.stringify(data));
};
const associateEmailWithUtk = (feedbackHubapiDomain, utk, email, submissionId, portalId, gdprSettings, isConsentsToReceiveCommunications) => {
  const request = buildRequest();
  const domain = getDomain(feedbackHubapiDomain);
  const url = buildUrl(`https://${domain}/${API_ROOT}/v1/identity-association/utk`);
  const data = {
    utk,
    email,
    portalId,
    submissionId,
    surveyType: 'KNOWLEDGE',
    gdprConsent: buildGdprConsent(gdprSettings, isConsentsToReceiveCommunications)
  };
  request.open('POST', url);
  request.setRequestHeader(CONTENT_TYPE_HEADER, JSON_CONTENT_TYPE);
  request.send(JSON.stringify(data));
};
const fetchConfig = (feedbackHubapiDomain, portalId, articleId, knowledgeBaseId, articleLanguage, ungatedForMultiLanguage, utk, callback) => {
  if (!callback) {
    throw new Error('fetchConfig expects a callback.');
  }
  const request = buildRequest();
  const domain = getDomain(feedbackHubapiDomain);
  let url = buildUrl(`https://${domain}/${API_ROOT}/v1/web-config/knowledge?portalId=${portalId}&articleId=${articleId}&knowledgeBaseId=${knowledgeBaseId}`);
  if (utk) {
    url += `&utk=${utk}`;
  }
  if (ungatedForMultiLanguage === 'true' && articleLanguage) {
    url += `&articleLanguageTag=${articleLanguage}`;
  }
  request.addEventListener('load', () => {
    const config = parseJson(request.responseText);
    if (config) {
      callback(config);
    }
  });
  request.open('GET', url);
  request.send();
};
const submit = (feedbackHubapiDomain, callback, {
  portalId,
  surveyId,
  articleId,
  value,
  contactId,
  utk,
  onError
}) => {
  if (!callback) {
    throw new Error('submit expects a callback');
  }
  const request = buildRequest();
  const domain = getDomain(feedbackHubapiDomain);
  const url = buildUrl(`https://${domain}/${API_ROOT}/v1/submit/knowledge`);
  const data = {
    portalId,
    surveyId,
    articleId,
    value,
    contactId,
    utk,
    webAnalytics: buildWebAnalytics(articleId)
  };
  request.addEventListener('load', () => {
    const response = parseJson(request.responseText);
    if (response) {
      callback(response);
    }
  });
  if (typeof onError === 'function') {
    request.addEventListener('error', onError);
  }
  request.open('POST', url);
  request.setRequestHeader(CONTENT_TYPE_HEADER, JSON_CONTENT_TYPE);
  request.setRequestHeader(ACCEPT_HEADER, JSON_CONTENT_TYPE);
  request.send(JSON.stringify(data));
};
export default {
  associateEmailWithContact,
  associateEmailWithUtk,
  fetchConfig,
  submit
};